<template>
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" style="margin-top: -4em;">
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <apuracao-contrato-finalizar-lote-industria-filtros
      v-model="abrirFiltro"
      @ApuracaoContratoFinalizarLoteFiltros__AplicaFiltros="aplicarFiltros"
      @ApuracaoContratoFinalizarLoteFiltros_conteinerCarregado="conteinerCarregado">
    </apuracao-contrato-finalizar-lote-industria-filtros>

    <v-container pa-0 mt-3 fluid v-resize="onResize" :style="`height: ${alturaCorpo}px; overflow-y: auto;`">
      <v-expansion-panels>
        <v-expansion-panel v-for="(contrato, i) in contratos"
            :key="i">
          <v-expansion-panel-header>
              <span class="subheading">
                {{`${$tc('label.contrato', 1)} ${contrato.idContrato} :: ${contrato.focoContrato} :: ${getMoney(contrato.vlrPagamento)}`}}
              </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <apuracao-contrato-finalizar-lote-industria-tabela
              ref="tabelaApuracoes"
              :id-contrato="contrato.idContrato"
              :filtros="filtros"
              @ApuracaoContratoFinalizarLoteIndustriaTabela__apuracoesSelecionadas
                  ="apuracoesSelecionadar">
            </apuracao-contrato-finalizar-lote-industria-tabela>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <p v-if="mostrarNaoEncontrado" class="text-xs-center">{{ $t('label.tabela_sem_conteudo') }}</p>

      <div id="ApuracaoContratoFinalizarLote__Rodape" class="ApuracaoContratoFinalizarLote__Rodape pa-2"
             :style="`width: ${tamanhoRodape}px`">
        <v-col :cols="xs ? 12 : 6">
          <v-pagination
            v-if="totalPagina > 1"
            v-model="numeroPagina"
            :length="totalPagina"
            :total-visible="$vuetify.breakpoint.mdAndUp ? 9 : 5"
            @input="buscarContratos(filtros)">
          </v-pagination>
        </v-col>
        <v-spacer v-if="!xs"></v-spacer>
        <v-btn v-show="temApuracaoSelecionada" @click="finalizarSelecionados" class="mr-2 my-auto">{{ $t('label.finalizar_selecionados') }}</v-btn>
        <v-btn color="primary" v-if="contratos.length" @click="finalizarTudo" class="my-auto">{{ $t('label.finalizar_tudo') }}</v-btn>
      </div>
    </v-container>

    <confirm ref="modalConfirmParcial" :message="$tc('message.finalizar_apuracao_alterar_status_aprovado', 2)"
             @agree="finalizarApuracoes(true)"
             @disagree="finalizarApuracoes(false)"
             show-cancel></confirm>
    <confirm ref="modalConfirm" :message="mensagemConfirmacao" @agree="confirmacaoFinalizar"></confirm>
    <confirm ref="modalErro" :message="mensagemErro" hide-disagree :agree-label="$t('label.ok')"></confirm>
    <confirm ref="modalPagamentoSemValor" :message="$tc('message.finalizar_apuracao_sem_pagamento', 1)"
             @agree="$refs.modalConfirm.open()"></confirm>

  </div>
</template>

<script>
import ApuracaoContratoFinalizarLoteIndustriaFiltros from './ApuracaoContratoFinalizarLoteIndustriaFiltros';
import { buscarTotalApuradoPorContrato, finalizarApuracoesIndustriaLote } from '../../../common/resources/apuracao/apuracao-contrato';
import { getMoney } from '../../../common/functions/helpers';
import ApuracaoContratoFinalizarLoteIndustriaTabela from './ApuracaoContratoFinalizarLoteIndustriaTabela';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ApuracaoContratoFinalizarLoteIndustria',
  components: {
    Confirm,
    ApuracaoContratoFinalizarLoteIndustriaTabela,
    ApuracaoContratoFinalizarLoteIndustriaFiltros,
  },
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
      abrirFiltro: false,
      idContrato: null,
      contratos: [],
      acoesSelecionadas: {},
      temApuracaoSelecionada: false,
      totalPagina: 1,
      tamanhoPagina: 10,
      numeroPagina: 1,
      filtros: {},
      tamanhoTela: window.innerWidth,
      tamanhoFiltros: 0,
      alturaTela: window.innerHeight,
      alturaRodaPe: 0,
      eventoClickCheckbox: null,
      jaBuscou: false,
      mensagemConfirmacao: '',
      mensagemErro: '',
      fechamentoParcial: false,
      confirmacaoFinalizar: () => {},
      contratosFinalizar: null,
    };
  },
  computed: {
    alturaCorpo() {
      return this.alturaTela - 150 - this.alturaRodaPe;
    },
    tamanhoRodape() {
      return this.tamanhoTela - (this.abrirFiltro && this.lg ? this.tamanhoFiltros : 0);
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    mostrarNaoEncontrado() {
      return !this.contratos.length && this.jaBuscou;
    },
  },
  methods: {
    apuracoesSelecionadar(idContrato, apuracoes, finalizaPagamento, fechamentoParcial) {
      if (apuracoes && apuracoes.length) {
        this.acoesSelecionadas[idContrato] = {
          finalizaPagamento,
          fechamentoParcial,
          apuracoes,
        };
      } else {
        delete this.acoesSelecionadas[idContrato];
      }
      this.temApuracaoSelecionada = !!Object.keys(this.acoesSelecionadas).length;
    },
    finalizarSelecionados() {
      const contratos = [];
      let finalizaPagamentoGeral = false;
      let finalizaOrcamentoGeral = false;
      let fechamentoParcial = false;
      Object.keys(this.acoesSelecionadas).forEach((id) => {
        const { finalizaPagamento } = this.acoesSelecionadas[id];
        const apuracoes = this.acoesSelecionadas[id].apuracoes
          .filter((a) => this.filtrarApuracoesComValor(a, finalizaPagamento));
        finalizaPagamentoGeral = finalizaPagamentoGeral || finalizaPagamento;
        finalizaOrcamentoGeral = finalizaOrcamentoGeral || !finalizaPagamento;
        fechamentoParcial = fechamentoParcial || this.acoesSelecionadas[id].fechamentoParcial;
        contratos.push({
          id,
          finalizaPagamento,
          fechamentoParcial: this.acoesSelecionadas[id].fechamentoParcial,
          apuracoes: this.acoesSelecionadas[id].apuracoes,
          temApuracaoSemValor: this.acoesSelecionadas[id].apuracoes.length !== apuracoes.length,
        });
      });
      this.finalizar(contratos, finalizaPagamentoGeral,
        finalizaOrcamentoGeral, fechamentoParcial);
    },
    finalizarTudo() {
      const contratos = [];
      let finalizaPagamentoGeral = false;
      let finalizaOrcamentoGeral = false;
      let fechamentoParcial = false;
      this.contratos.forEach((contrato, index) => {
        const contratoFormatado = this.$refs.tabelaApuracoes[index]
          .getContrato();
        const { finalizaPagamento } = contratoFormatado;
        const apuracoes = contratoFormatado.apuracoes
          .filter((a) => this.filtrarApuracoesComValor(a, finalizaPagamento));
        finalizaPagamentoGeral = finalizaPagamentoGeral || finalizaPagamento;
        finalizaOrcamentoGeral = finalizaOrcamentoGeral || !finalizaPagamento;
        fechamentoParcial = fechamentoParcial || contratoFormatado.fechamentoParcial;
        contratoFormatado.temApuracaoSemValor = contratoFormatado
          .apuracoes.length !== apuracoes.length;
        contratos.push(contratoFormatado);
      });
      this.finalizar(contratos, finalizaPagamentoGeral,
        finalizaOrcamentoGeral, fechamentoParcial);
    },
    filtrarApuracoesComValor(apuracao, finalizaPagamento) {
      if (finalizaPagamento) {
        return apuracao.vlrAPagar > 0;
      }
      return apuracao.vlrAAportar > 0;
    },
    validarFinalizacoes(contratos) {
      let valido = true;
      if (!contratos.length) {
        this.mensagemErro = this.$tc('message.finalizar_apuracao_todas_sem_pagamento', 1);
        setTimeout(() => this.$refs.modalErro.open());
        return false;
      }

      for (let i = 0; i < contratos.length && valido; i += 1) {
        const { apuracoes } = contratos[i];
        for (let y = 0; y < apuracoes.length; y += 1) {
          const apuracao = apuracoes[y];
          if (contratos[i].finalizaPagamento
            && apuracao.vlrAPagar > apuracao.maxAPagar) {
            valido = false;
            this.mensagemErro = this.$tc('message.valor_pagar_maior_que_apurado', 1);
            setTimeout(() => this.$refs.modalErro.open());
          }
          if (!contratos[i].finalizaPagamento
            && apuracao.vlrAAportar > apuracao.maxAAportar) {
            valido = false;
            this.mensagemErro = this.$tc('message.valor_aportar_nao_permitido', 1);
            setTimeout(() => this.$refs.modalErro.open());
          }
        }
      }
      return valido;
    },
    finalizar(contratos, finalizaPagamentoGeral,
      finalizaOrcamentoGeral, fechamentoParcial) {
      if (this.validarFinalizacoes(contratos)) {
        if (finalizaPagamentoGeral && finalizaOrcamentoGeral) {
          this.mensagemConfirmacao = this.$tc('message.finalizar_apuracoes_lote_aportar_pagamento', 1);
        } else if (finalizaPagamentoGeral) {
          this.mensagemConfirmacao = this.$tc('message.finalizar_apuracoes_lote_pagamento', 1);
        } else {
          this.mensagemConfirmacao = this.$tc('message.finalizar_apuracoes_lote_aportar', 1);
        }
        this.fechamentoParcial = fechamentoParcial;
        this.contratosFinalizar = contratos;
        if (this.fechamentoParcial) {
          this.confirmacaoFinalizar = this.$refs.modalConfirmParcial.open;
        } else {
          this.confirmacaoFinalizar = this.finalizarApuracoes;
        }
        if (this.temApuracoesSemValor(contratos)) {
          setTimeout(() => this.$refs.modalPagamentoSemValor.open());
        } else {
          setTimeout(() => this.$refs.modalConfirm.open());
        }
      }
    },
    temApuracoesSemValor(contratos) {
      return !!contratos.find((c) => c.temApuracaoSemValor);
    },
    finalizarApuracoes(alterarStatus = true) {
      const contratosApuracoes = this.prepararParametros(alterarStatus);
      finalizarApuracoesIndustriaLote({}, this.$resource, contratosApuracoes)
        .then(() => {
          this.limparArraysControladores();
          this.aplicarFiltros(this.filtros);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    prepararParametros(alterarStatus) {
      const contratos = [];

      this.contratosFinalizar.forEach((c) => {
        const contrato = {
          id: c.id,
          alterarStatus,
          finalizaEmPagamento: c.finalizaPagamento,
          fechamentoParcial: c.fechamentoParcial,
        };

        contrato.apuracoes = [];
        c.apuracoes.forEach((a) => {
          contrato.apuracoes.push({
            id: a.idApuracao,
            idAcao: a.idAcao,
            vlrAPagar: a.vlrAPagar,
            vlrAAportar: a.vlrAAportar,
          });
        });

        contratos.push(contrato);
      });
      return contratos;
    },
    onResize() {
      this.alturaRodaPe = document.getElementById('ApuracaoContratoFinalizarLote__Rodape').offsetHeight;
      this.tamanhoTela = window.innerWidth;
      this.alturaTela = window.innerHeight;
    },
    getMoney,
    aplicarFiltros(filtros) {
      this.numeroPagina = 1;
      this.contratos = [];
      this.buscarContratos(filtros);
    },
    limparArraysControladores() {
      this.acoesSelecionadas = [];
      this.temApuracaoSelecionada = false;
      this.contratosFinalizar = null;
    },
    buscarContratos(filtros) {
      filtros.tamanhoPagina = this.tamanhoPagina;
      filtros.numeroPagina = this.numeroPagina;
      this.filtros = filtros;
      return buscarTotalApuradoPorContrato(filtros, this.$resource)
        .then((response) => {
          this.contratos = response.data.resposta;
          this.limparArraysControladores();
          this.totalPagina = Math
            .ceil(response.data.quantidadeRegistrosPagina / this.tamanhoPagina);
          this.jaBuscou = true;
        })
        .catch((err) => {
          this.$error(this, err);
          this.jaBuscou = true;
        });
    },
    conteinerCarregado() {
      this.tamanhoFiltros = document.getElementById('ApuracaoContratoFinalizarLoteFiltros__filtro_rapido').offsetWidth;
    },
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
  },
  mounted() {
    this.alturaRodaPe = document.getElementById('ApuracaoContratoFinalizarLote__Rodape').offsetHeight;
    this.abrirFiltro = true;
  },
};
</script>

<style scoped>
  .ApuracaoContratoFinalizarLote__Rodape {
    display: flex;

    position: fixed;
    z-index: 2;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
  }
</style>
